<template>
    <div class="layout-app-table">
        <router-view></router-view>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.layout-app-table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    background-image: linear-gradient(to bottom, var(--color-layout-gradient-from), var(--color-layout-gradient-to));
}
</style>